"use strict";

import c3 from "c3";
import d3 from "d3";
import ChartBase from "../ChartBase";

class ChartReturningCustomers extends ChartBase {

    constructor(container) {

        super(container);
        var self = this;
        self.donutValueSelector = container + " .js-value .c3-chart-arcs-title";
        self.generateDonut();

        d3.json(`${self.url}/api/restaurantdata/${self.restaurantId}/lunchguestsrevisited`)
        .header("Accept-Language", d3.select("html").attr("lang"))
        .get(function(error, data) {
        // d3.json(`../../../lunchguestsrevisited.json`, function(error, data) {

            if (error || data.reslut === null) {
                self.displayError();
                return;
            } else {
                self.hideLoader();
            }

            self.data = data;
            self.weekData = data.result.week;
            self.monthData = data.result.month;
            self.yearData = data.result.year;

            var returnFrequency = self.getReturnFrequency(self.yearData);
            var displaydata = self.getDataChunks(self.yearData);

            self.chart = c3.generate({
                bindto: self.chartSelector,
                size: {
                    height: 240
                },
                data: {
                    json: displaydata,
                    type: "bar",
                    labels: {
                        format: function (v) {
                            return self.getBarLabel(v);
                        }
                    },
                    mimeType: "json",
                    keys: {
                        x: "Transactions",
                        value: ["Guests"]
                    },
                    color: function () {
                        return "#ea6649";
                    }
                },
                bar: {
                    width: {
                        ratio: 0.5
                    }
                },
                padding: {
                    left: 10
                },
                axis: {
                    x: {
                        // label: {
                        //     text: "X antal besök, Y antal gäster",
                        //     position: "outer-center"
                        // },
                        type: "category",
                        max: 4,
                        tick: {
                            outer: false
                        }
                    },
                    y: {
                        tick: {
                            outer: false,
                            count: 3,
                            format: function (d) { return Math.round(d); }
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    format: {
                        value: function (value) {
                            /*jshint ignore:start*/
                            /*eslint-disable*/
                            return `${texts.graphGuests}: ${value}`;
                            /*eslint-enable*/
                            /*jshint ignore:end*/
                        }
                    }
                }
            });

            // Init controls
            self.initControls(false);
            self.appendArrows();
            self.positionLabels();

            self.populateDonut(returnFrequency);
        });
    }

    positionLabels() {
        var self = this;
        var elSelector = `${self.container} .js-chart .c3-texts .c3-text`;
        d3.selectAll(elSelector)
            .attr("transform", "translate(0, -5)");
    }

    getBarLabel(visitors) {
        var self = this;
        var totalVisitors = self.getTotalVisitors();
        if (totalVisitors > 0) {
            return `${Math.round(visitors / totalVisitors * 100)}%`;
        } else {
            return `0%`;
        }
    }

    getDataChunks(data) {

        if (data.length < 5) {
            return data;
        } else {
            var firstFour = data.slice(0, 4);
            var tempArray = data.slice(4);
            var min = tempArray[0].Transactions;
            // var max = tempArray[tempArray.length - 1].Transactions;

            var totalGuests = tempArray.reduce(function(a, b) {
                return a + b.Guests;
            }, 0);

            var theRest = {
                "Guests": totalGuests,
                "Transactions": `${min}+`
            };

            return firstFour.concat(theRest);
        }
    }

    generateDonut() {
        var self = this;

        self.donut = c3.generate({
            bindto: self.valueSelector,
            data: {
                columns: [
                    ["show", 0],
                    ["dontshow", 1]
                ],
                type: "donut",
                order: null
            },
            transition: {
                duration: 1000
            },
            size: {
                height: 200
            },
            color: {
                pattern: ["#ea6649", "#f4f4f6"]
            },
            legend: {
                show: false
            },
            donut: {
                label: {
                    show: false
                },
                title: 0,
                width: 15,
                expand: false
            },
            tooltip: {
                show: false
            }
        });
    }

    populateDonut(returnFrequency) {
        var self = this;
        var percentage = self.getMoreThanOnceFrequency(returnFrequency) || 0;
        var i = 1;
        if (percentage > 0) {
            var timer = setInterval(function () {
                if (i >= Math.round(percentage * 100)) {
                    clearInterval(timer);
                }
                d3.select(self.donutValueSelector).text(i++ + "%");
            }, 30);
        } else {
            d3.select(self.donutValueSelector).text(percentage + "%");
        }

        self.donut.load({
            columns:[
                ["show", percentage],
                ["dontshow", 1 - percentage]
            ]
        });
    }

    getReturnFrequency(data) {

        var returnFrequency = [];
        data.forEach(function(d, i) {
            var value = parseInt(data[i].Transactions);

            returnFrequency[i] = {
                "frequency": value,
                "visitors": parseInt(data[i].Guests),
                "span": data[i].span
            };
        });
        var totalVisitors = 0;
        returnFrequency.forEach(function(d, i) {
            totalVisitors = totalVisitors + returnFrequency[i].visitors;
        });
        returnFrequency.forEach(function(d, i) {
            returnFrequency[i].percentage =  Math.round(returnFrequency[i].visitors / totalVisitors * 100);
        });

        return returnFrequency;
    }

    getTotalVisitors() {
        var self = this;
        var data;

        if (self.range === "year") {
            data = self.yearData;
        } else if (self.range === "month") {
            data = self.monthData;
        } else {
            data = self.weekData;
        }

        var visitors = 0;

        data.forEach(function(d, i) {
            visitors += parseInt(data[i].Guests);
        });

        return visitors;
    }

    updateData() {

        var self = this;
        var data;

        if (self.range === "year") {
            data = self.yearData;
        } else if (self.range === "month") {
            data = self.monthData;
        } else {
            data = self.weekData;
        }

        var returnFrequency = self.getReturnFrequency(data);
        var displaydata = self.getDataChunks(data);

        self.chart.load({
            json: displaydata,
            mimeType: "json",
            keys: {
                x: "Transactions",
                value: ["Guests"]
            },
            done: function() {
                self.populateDonut(returnFrequency);
            }
        });
    }

    getMoreThanOnceFrequency(returnFrequency) {
        var once = 0;
        var moreThanOnce = 0;

        returnFrequency.forEach(function(d) {
            if (d.frequency > 1) {
                moreThanOnce += d.visitors;
            } else {
                once = d.visitors;
            }
        });

        return moreThanOnce / (once + moreThanOnce);
    }
}

export default ChartReturningCustomers;
