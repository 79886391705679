"use strict";

import d3 from "d3";

class Nav {

    constructor() {
        var self = this;
        d3.select(".js-nav-handle").on("click", self.toggleNav);
        d3.select(".js-settings").on("click", self.toggleSettings);
    }

    toggleNav() {
        var body = d3.select("body");
        body.classed("menu-open", !body.classed("menu-open"));
    }

    toggleSettings() {
        d3.event.preventDefault();
        var body = d3.select("body");
        body.classed("settings-open", !body.classed("settings-open"));
    }
}

export default Nav;
