"use strict";

import d3 from "d3";

class Tooltip {

    constructor() {
        d3.selectAll(".js-tooltip").on("click", function() {
            d3.event.stopPropagation();
            d3.selectAll(".js-tooltip").classed("tooltip--active", false);
            var target = d3.select(this);
            target.classed("tooltip--active", !target.classed("tooltip--active"));
        });

        d3.select("body").on("click", function() {
            d3.selectAll(".js-tooltip").classed("tooltip--active", false);
        });
    }
}

export default Tooltip;
