"use strict";

import c3 from "c3";
import d3 from "d3";
// import odometer from "odometer";
import ChartBase from "../ChartBase";

class ChartPricePerLunch extends ChartBase {

    constructor(container) {

        super(container);
        var self = this;
        self.currency = document.body.getAttribute("data-currency");

        // d3.json(`../../../test.json`)
        d3.json(`${self.url}/api/restaurantdata/${self.restaurantId}/avglunchprice`)
        .header("Accept-Language", d3.select("html").attr("lang"))
        .get(function(error, data) {

            if (error || data.result === null) {
                self.displayError();
                return;
            }

            d3.json(`${self.url}/api/restaurantdata/${self.restaurantId}/avglunchpricearea`)
            .header("Accept-Language", d3.select("html").attr("lang"))
            .get(function(subError, subData) {

                if (subError || subData.result === null) {
                    self.displayError();
                    return;
                } else {
                    self.hideLoader();
                }

                self.subData = subData;
                self.subWeekData = subData.result.week;
                self.subMonthData = subData.result.month;
                self.subYearData = subData.result.year;
                self.subNote = subData.meta.note;

                self.data = data;
                self.weekData = self.getWeekData(data.result);
                self.weekData.subData = subData.result.week;
                self.weekData.subData.percentage = Math.round(parseFloat(self.weekData.subData.percentage));
                self.monthData = self.getMonthData(data.result);
                self.monthData.subData = subData.result.month;
                self.monthData.subData.percentage = Math.round(parseFloat(self.monthData.subData.percentage));
                self.yearData = self.getYearData(data.result);
                self.yearData.subData = subData.result.year;
                self.yearData.subData.percentage = Math.round(parseFloat(self.yearData.subData.percentage));

                // var od = new odometer({
                //     el: document.querySelector(self.valueSelector),
                //     theme: "minimal"
                // });

                d3.select(self.valueSelector)
                    .html(`${Math.round(parseFloat(self.yearData.subData.value))}`);
                d3.select(self.subValueSelector)
                    .html(self.renderSubValue(self.yearData));
                d3.select(self.noteSelector)
                    .html(self.subNote);

                self.disableButtons();

                self.chart = c3.generate({
                    bindto: self.chartSelector,
                    size: {
                        height: 210
                    },
                    data: {
                        json: self.yearData,
                        type: "spline",
                        mimeType: "json",
                        keys: {
                            x: "date",
                            value: ["value"]
                        },
                        color: function () {
                            return "#c2ddef";
                        }
                    },
                    padding: {
                        left: 10
                    },
                    point: {
                        r: 5,
                        focus: {
                            expand: {
                                enabled: false
                            }
                        }
                    },
                    axis: {
                        x: {
                            type: "timeseries",
                            tick: {
                                outer: false,
                                format: function (x) {
                                    return self.localizeDate(x);
                                }
                            }
                        },
                        y: {
                            tick: {
                                outer: false,
                                count: 3,
                                format: function (d) { return Math.round(d); }
                            }
                        }
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        format: {
                            value: function (value) { return value + " " + self.currency; }
                        }
                    }
                });

                self.initControls(false);
                self.appendArrows();
            });

        });
    }

    updateData() {

        var self = this;
        var data;

        if (self.range === "year") {
            data = self.yearData;
        } else if (self.range === "month") {
            data = self.monthData;
        } else {
            data = self.weekData;
        }

        self.chart.load({
            json: data,
            mimeType: "json",
            keys: {
                x: "date",
                value: ["value"]
            }
        });

        d3.select(self.valueSelector)
            .html(`${Math.round(parseFloat(data.subData.value))}`);
        d3.select(self.subValueSelector)
            .html(self.renderSubValue(data));
    }

    renderSubValue(data) {

        var self = this;
        var value = data.subData.percentage;

        if (value < 0) {
            return `${Math.abs(value)} % ${self.texts.under}`;
        } else {
            return `${Math.abs(value)} % ${self.texts.over}`;
        }
    }

    initControls(flush = true) {

        var buttons = d3.selectAll(this.rangeSelector);
        d3.select(buttons[0][2]).classed("btn--active", true);

        buttons.on("click", function() {
            var button = d3.event.target;
            this.range = button.getAttribute("data-range");
            this.updateData(true);
            if (flush) {
                this.chart.flush();
            }
            this.updateControlUI(button);
        }.bind(this));
    }

    getYearData(data) {

        data = data.slice(0, 365);
        var yearData = {};

        data.forEach(function(d) {
            var year = new Date(d.date).getFullYear();
            var month = new Date(d.date).getMonth();
            var identifier = new Date(year, month).getTime();

            if (yearData[identifier] === undefined) {
                yearData[identifier] = {
                    "days": 0,
                    "sum": 0
                };
            }

            if (d.value !== "0") {
                yearData[identifier].days = yearData[identifier].days + 1;
                yearData[identifier].sum = parseFloat(yearData[identifier].sum) + parseFloat(d.value);
            }
        });

        data = [];
        for (var key in yearData) {
            var date = parseInt(key);
            // var days = yearData[key].days === 0 ? 1 : yearData[key].days;
            var days = yearData[key].days || 1;
            var value = (Math.round(yearData[key].sum / days));
            data.push({"value": value, "date": date});
        }

        // console.log(data);

        if (data.length > 12) {
            data = data.slice(data.length - 12);
        }

        return data;
    }
}

export default ChartPricePerLunch;
