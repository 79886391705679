"use strict";

function splitArray(a, n) {
    var len = a.length;
    var out = [];
    var i = 0;

    while (i < len) {
        var size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, i + size));
        i += size;
    }
    return out;
}

export default splitArray;
