"use strict";

import d3 from "d3";

class Contest {

    constructor(){
        let contestContainerClass = ".js-contest-title";
        let titleText = document.querySelector(contestContainerClass)
            .getAttribute("data-text");

        let rulesToggleBtns = document.getElementsByClassName(".js-contest-toggle-rules");

        if(rulesToggleBtns.length){
            rulesToggleBtns[0].addEventListener("click", function(e){
                document.getElementsByClassName(".js-contest-rules")[0]
                    .style.display = "block";
                rulesToggleBtns[0].style.display = "none";
                rulesToggleBtns[1].style.display = "block";
            });

            rulesToggleBtns[1].addEventListener("click", function(){
                document.getElementsByClassName(".js-contest-rules")[0]
                    .style.display = "none";
                rulesToggleBtns[0].style.display = "block";
                rulesToggleBtns[1].style.display = "none";
            });
        }

        this.curvedHeadline(contestContainerClass, titleText);
    }

    curvedHeadline(container, text) {
        let d = "M 0,187 A 199,200 0 0,1 400,300";
        let viewBox = "0 0 400 70";
        if(text.length > 17) {
            d = "M 0,170 A 199,200 0 0,1 400,290";
            viewBox = "0 0 400 78";
        }

        let svg = d3.select(container).append("svg")
            .classed("contest__svg", true)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", viewBox);

        //Create an SVG path
        svg.append("path")
            .attr("id", "wavy") //very important to give the path element a unique ID to reference later
            .attr("d", d)
            .style("fill", "none");

        //Create an SVG text element and append a textPath element
        svg.append("text")
            .attr("fill", "#FFF")
            .append("textPath") //append a textPath to the text element
            .attr("xlink:href", "#wavy") //place the ID of the path here
            .style("text-anchor","middle") //place the text halfway on the arc
            .attr("startOffset", "41%")
            .text(text);
      }

}

export default Contest;
