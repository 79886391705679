"use strict";

function mediaQuery(query) {
    if (window.matchMedia) {
        var mq = window.matchMedia("(" + query + ")");
        return mq.matches;
    } else {
        return false;
    }
}

export default mediaQuery;
