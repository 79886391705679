"use strict";

import d3 from "d3";
import odometer from "odometer";
// import { mediaQuery } from "../../utils/";

class VisitedGuests {

    constructor(container) {

        var self = this;
        self.container = container;
        self.url = document.body.getAttribute("data-url");
        self.restaurantId = document.body.getAttribute("data-restaurant");
        self.dayValueSelector = container + " .js-day-value";
        self.dayIndicatorSelector = container + " .js-day-indicator";
        self.weekValueSelector = container + " .js-week-value";
        self.weekIndicatorSelector = container + " .js-week-indicator";
        self.monthValueSelector = container + " .js-month-value";
        self.monthIndicatorSelector = container + " .js-month-indicator";
        self.loaderSelector = container + " .js-loader";
        self.loaderContainerSelector = container + " .js-loader-container";
        self.displayValue = null;

        d3.json(`${self.url}/api/restaurantdata/${self.restaurantId}/visitedguests`)
        .header("Accept-Language", d3.select("html").attr("lang"))
        .get(function(error, data) {
        // d3.json(`../../../visitedguests.json`, function(error, data) {

            if (error || data.result === null) {
                self.displayError();
                return;
            } else {
                self.hideLoader();
            }

            var day = parseInt(data.result.day);
            self.displayValue = day;
            var prevDay = parseInt(data.result.prevDay);
            var week = parseInt(data.result.week);
            var prevWeek = parseInt(data.result.prevWeek);
            var month = parseInt(data.result.month);
            var prevMonth = parseInt(data.result.prevMonth);

            var dayPercent = 0;
            var weekPercent = 0;
            var monthPercent = 0;

            var daySign = "+";
            var weekSign = "+";
            var monthSign = "+";

            if (day !== undefined && day !== 0 && prevDay !== undefined) {
                dayPercent = Math.round(((day - prevDay) / prevDay) * 100);

                if (dayPercent === Infinity) {
                    dayPercent = 100;
                }

                if (dayPercent < 0) {
                    daySign = "";
                    d3.select(self.dayIndicatorSelector).classed("dashboard__indicator--up", false);
                    d3.select(self.dayIndicatorSelector).classed("dashboard__indicator--down", true);
                }
            }

            if (week !== undefined && week !== 0 && prevWeek !== undefined) {
                weekPercent = Math.round(((week - prevWeek) / prevWeek) * 100);

                if (weekPercent === Infinity) {
                    weekPercent = 100;
                }

                if (weekPercent < 0) {
                    weekSign = "";
                    d3.select(self.weekIndicatorSelector).classed("dashboard__indicator--up", false);
                    d3.select(self.weekIndicatorSelector).classed("dashboard__indicator--down", true);
                }
            }

            if (month !== undefined && month !== 0 && prevMonth !== undefined) {
                monthPercent = Math.round(((month - prevMonth) / prevMonth) * 100);

                if (monthPercent === Infinity) {
                    monthPercent = 100;
                }

                if (monthPercent < 0) {
                    monthSign = "";
                    d3.select(self.monthIndicatorSelector).classed("dashboard__indicator--up", false);
                    d3.select(self.monthIndicatorSelector).classed("dashboard__indicator--down", true);
                }
            }

            d3.select(self.dayValueSelector).html(0);
            d3.select(self.weekValueSelector).html(week);
            d3.select(self.monthValueSelector).html(month);

            d3.select(self.dayIndicatorSelector).html(`${daySign}${dayPercent}%`);
            d3.select(self.weekIndicatorSelector).html(`${weekSign}${weekPercent}%`);
            d3.select(self.monthIndicatorSelector).html(`${monthSign}${monthPercent}%`);

            self.scrollHandler();
        });
    }

    scrollHandler() {
        var self = this;
        var notTriggered = true;

        // if (self.displayValue === 0 || !mediaQuery("min-width: 960px")) {
        //     d3.select(self.dayValueSelector).text(self.displayValue);
        //     return;
        // }

        window.addEventListener("scroll", function() {
            var elInfo = document.querySelector(self.container).getBoundingClientRect();

            if (elInfo.top < window.innerHeight && notTriggered) {
                notTriggered = false;

                /*jshint ignore:start*/
                /*eslint-disable*/
                var od = new odometer({
                    el: document.querySelector(self.dayValueSelector),
                    format: "d",
                    theme: "minimal"
                });
                od.update(self.displayValue);
                /*eslint-enable*/
                /*jshint ignore:end*/
            }
        });
    }

    displayError() {
        var self = this;
        var message = document.querySelector(self.loaderSelector).getAttribute("data-errormessage");
        d3.select(d3.select(self.loaderSelector).node().parentNode).classed("chart--error", true);
        d3.select(self.loaderSelector).html(message);
    }

    hideLoader() {
        var self = this;
        document.querySelector(self.loaderContainerSelector)
            .classList.add("dashboard__loader-container--hidden");
    }
}

export default VisitedGuests;
