"use strict";

import d3 from "d3";

class ChartBase {

    constructor(container) {

        var self = this;
        self.container = container;
        /*jshint ignore:start*/
        /*eslint-disable*/
        self.texts = texts;
        /*eslint-enable*/
        /*jshint ignore:end*/
        self.url = document.body.getAttribute("data-url");
        self.restaurantId = document.body.getAttribute("data-restaurant");
        self.container = container;
        self.chartSelector = container + " .js-chart";
        self.valueSelector = container + " .js-value";
        self.subValueSelector = container + " .js-subvalue";
        self.loaderSelector = container + " .js-loader";
        self.loaderContainerSelector = container + " .js-loader-container";
        self.rangeSelector = container + " .js-range";
        self.noteSelector = container + " .js-note";
        self.range = "year";
        self.data = null;
        self.weekData = null;
        self.monthdata = null;
        self.yeardata = null;
        self.chart = null;
    }

    appendArrows() {

        var self = this;
        var svgSelector = `${self.container} .js-chart svg`;
        var svg = d3.select(svgSelector);
        svg.select("defs").remove();
        var defs = svg.append("svg:defs");

        defs.append("svg:marker")
            .attr("id", "xarrow")
            .attr("class", "arrow")
            .attr("viewBox", "-5 -5 10 10")
            .attr("refX", 4)
            .attr("refY", 0)
            .attr("markerWidth", 8)
            .attr("markerHeight", 8)
            .attr("orient", "auto")
            .append("svg:path")
            .attr("d", "M 0,0 m -5,-5 L 5,0 L -5,5 Z")
            .attr("fill", "#747a8d");

        defs.append("svg:marker")
            .attr("id", "yarrow")
            .attr("class", "arrow")
            .attr("viewBox", "-5 -5 10 10")
            .attr("refX", 0)
            .attr("refY", 0)
            .attr("markerWidth", 8)
            .attr("markerHeight", 8)
            .attr("orient", "auto")
            .append("svg:path")
            .attr("d", "M 0,0 m -5,-5 L 5,0 L -5,5 Z")
            .attr("fill", "#747a8d")
            .attr("transform", "rotate(-90)");

        var xaxis = d3.select(`${svgSelector} .c3-axis-x path`);
        xaxis.attr("marker-end", function(){ return "url(#xarrow)"; });
        var yaxis = d3.select(`${svgSelector} .c3-axis-y path`);
        yaxis.attr("marker-start", function(){ return "url(#yarrow)"; });
    }

    updateData() {

        var self = this;
        var data;

        if (self.range === "year") {
            data = self.yearData;
        } else if (self.range === "month") {
            data = self.monthData;
        } else {
            data = self.weekData;
        }

        self.chart.load({
            json: data,
            mimeType: "json",
            keys: {
                x: "date",
                value: ["value"]
            }
        });
    }

    initControls(flush = true) {
        var self = this;

        var buttons = d3.selectAll(self.rangeSelector);
        d3.select(buttons[0][2]).classed("btn--active", true);

        buttons.on("click", function() {
            var button = d3.event.target;
            self.range = button.getAttribute("data-range");
            self.updateData();
            if (flush) {
                self.chart.flush();
            }
            self.updateControlUI(button);
        });
    }

    updateControlUI(elem) {

        var sibling = elem.parentNode.firstChild;
        for (; sibling; sibling = sibling.nextSibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                sibling.classList.remove("btn--active");
            }
        }
        elem.classList.add("btn--active");
    }

    getWeekData(data) {
        return data.slice(data.length - 7);
    }

    getMonthData(data) {
        return data.slice(data.length - 31);
    }

    getYearData(data) {
        return data.slice(0, 365);
    }

    localizeDate(date) {
        var self = this;
        var year;
        var month;
        var day;

        if (self.range === "year") {
            year = date.getFullYear();
            month = date.getMonth();
            return `${self.texts.months[month]} ${year.toString().substring(2)}`;
        } else if (self.range === "month") {
            month = date.getMonth();
            day = date.getDate();
            return `${self.texts.months[month]} ${day}`;
        } else {
            day = date.getDay();
            return self.texts.days[day];
        }
    }

    displayError() {
        var self = this;
        var message = document.querySelector(self.loaderSelector).getAttribute("data-errormessage");
        d3.select(d3.select(self.loaderSelector).node().parentNode).classed("chart--error", true);
        d3.select(self.loaderSelector).html(message);
    }

    hideLoader() {
        var self = this;
        document.querySelector(self.loaderContainerSelector)
            .classList.add("dashboard__loader-container--hidden");
    }

    disableButtons() {
        var self = this;
        var dataLength = self.data.result.length;
        var weekRangeButton = document.querySelector(self.container + " .js-range[data-range='week']");
        var monthRangeButton = document.querySelector(self.container + " .js-range[data-range='month']");
        var yearRangeButton = document.querySelector(self.container + " .js-range[data-range='year']");

        if (dataLength < 7) {
            weekRangeButton.classList.add("btn--diabled");
            monthRangeButton.classList.add("btn--diabled");
            yearRangeButton.classList.add("btn--diabled");
        } else if (dataLength < 27) {
            monthRangeButton.classList.add("btn--diabled");
            yearRangeButton.classList.add("btn--diabled");
        } else if (dataLength < 365) {
            yearRangeButton.classList.add("btn--diabled");
        }
    }
}

export default ChartBase;
