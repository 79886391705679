"use strict";

import c3 from "c3";
import d3 from "d3";
import ChartBase from "../ChartBase";

class ChartIncome extends ChartBase {

    constructor(container) {

        super(container);
        var self = this;
        self.range = "month";
        self.currency = document.body.getAttribute("data-currency");

        d3.json(`${self.url}/api/restaurantdata/${self.restaurantId}/restaurantincome`)
        .header("Accept-Language", d3.select("html").attr("lang"))
        .get(function(error, data) {

            if (error || data.result === null) {
                self.displayError();
                return;
            }

            self.hideLoader();

            self.data = data;
            self.weekData = self.getWeekData(data.result);
            self.weekData.subData = {
                value: data.total.week,
                percentage: 0
            };

            self.monthData = self.getMonthData(data.result);
            self.monthData.subData = {
                value: data.total.month,
                percentage: 0
            };

            self.yearData = self.getYearData(data.result);
            self.yearData.subData = {
                value: data.total.year,
                percentage: 0
            };

            d3.select(self.valueSelector)
                .html(`${Math.round(parseFloat(self.monthData.subData.value))}`);

            self.chart = c3.generate({
                bindto: self.chartSelector,
                size: {
                    height: 210
                },
                data: {
                    json: self.monthData,
                    type: "spline",
                    mimeType: "json",
                    keys: {
                        x: "date",
                        value: ["value"]
                    },
                    color: function () {
                        return "#ecbe7c";
                    }
                },
                padding: {
                    left: 10
                },
                point: {
                    r: 5,
                    focus: {
                        expand: {
                            enabled: false
                        }
                    }
                },
                axis: {
                    x: {
                        type: "timeseries",
                        tick: {
                            outer: false,
                            format: function (x) {
                                return self.localizeDate(x);
                            }
                        }
                    },
                    y: {
                        tick: {
                            outer: false,
                            count: 3,
                            format: function (d) { return Math.round(d); }
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    format: {
                        value: function (value) { return value + " " + self.currency; }
                    }
                }
            });

            self.initControls(false);
            self.appendArrows();

        });
    }

    updateData() {

        var self = this;
        var data;

        if (self.range === "year") {
            data = self.yearData;
        } else if (self.range === "month") {
            data = self.monthData;
        } else {
            data = self.weekData;
        }

        self.chart.load({
            json: data,
            mimeType: "json",
            keys: {
                x: "date",
                value: ["value"]
            }
        });

        d3.select(self.valueSelector)
            .html(`${Math.round(parseFloat(data.subData.value))}`);
    }

    initControls(flush = true) {

        var buttons = d3.selectAll(this.rangeSelector);
        d3.select(buttons[0][1]).classed("btn--active", true);

        buttons.on("click", function() {
            var button = d3.event.target;
            this.range = button.getAttribute("data-range");
            this.updateData(true);
            if (flush) {
                this.chart.flush();
            }
            this.updateControlUI(button);
        }.bind(this));
    }

    getYearData(data) {

        data = data.slice(0, 365);
        var yearData = {};

        data.forEach(function(d) {
            var year = new Date(d.date).getFullYear();
            var month = new Date(d.date).getMonth();
            var identifier = new Date(year, month).getTime();

            if (yearData[identifier] === undefined) {
                yearData[identifier] = {
                    "days": 0,
                    "sum": 0
                };
            }

            if (d.value !== "0") {
                yearData[identifier].days = yearData[identifier].days + 1;
                yearData[identifier].sum = parseFloat(yearData[identifier].sum) + parseFloat(d.value);
            }
        });

        data = [];
        for (var key in yearData) {
            var date = parseInt(key);
            // var days = yearData[key].days === 0 ? 1 : yearData[key].days;
            var days = yearData[key].days || 1;
            data.push({"value": yearData[key].sum, "date": date});
        }

        if (data.length > 12) {
            data = data.slice(data.length - 12);
        }

        return data;
    }

    localizeDate(date) {
        var self = this;
        var year;
        var month;
        var day;

        if (self.range === "year") {
            year = date.getFullYear();
            month = date.getMonth();
            return `${self.texts.months[month]}`;
        } else if (self.range === "month") {
            month = date.getMonth();
            day = date.getDate();
            return `${self.texts.months[month]} ${day}`;
        } else {
            day = date.getDay();
            return self.texts.days[day];
        }
    }
}

export default ChartIncome;
