"use strict";

import ChartPricePerLunch from "./libs/chartpriceperlunch";
import ChartReturningCustomers from "./libs/chartreturningcustomers";
import PotentialLunchGuests from "./libs/potentiallunchguests";
import VisitedGuests from "./libs/visitedguests";
import CardStatistics from "./libs/cardstatistics";
import Nav from "./libs/nav";
import Tooltip from "./libs/tooltip";
import Usp from "./libs/usp";
import ChartIncome from "./libs/chartincome";
import Contest from "./libs/contest";
import PostSuggestion from "./libs/postsuggestion";

if (document.querySelector(".js-chart-priceperlunch-container") !== null) {
    new ChartPricePerLunch(".js-chart-priceperlunch-container");
}

if (document.querySelector(".js-chart-returningcustomers-container") !== null) {
    new ChartReturningCustomers(".js-chart-returningcustomers-container");
}

if (document.querySelector(".js-chart-potentialguests-container") !== null) {
    new PotentialLunchGuests(".js-chart-potentialguests-container");
}

if (document.querySelector(".js-chart-visitedguests-container") !== null) {
    new VisitedGuests(".js-chart-visitedguests-container");
}

if (document.querySelector(".js-chart-cardstatistics-container") !== null) {
    new CardStatistics(".js-chart-cardstatistics-container");
}

if (document.querySelector(".js-nav-handle") !== null || document.querySelector(".js-settings") !== null) {
    new Nav();
}

if (document.querySelector(".js-tooltip") !== null) {
    new Tooltip();
}

if (document.querySelector(".js-usp") !== null) {
    new Usp();
}

if(document.querySelector(".js-chart-restaurantincome-container") !== null) {
    new ChartIncome(".js-chart-restaurantincome-container");
}

if(document.querySelector(".js-contest") !== null) {
    new Contest();
}

if(document.querySelector(".js-suggestions") !== null) {
    new PostSuggestion();
}