"use strict";

import d3 from "d3";
import odometer from "odometer";

class PostSuggestion {

    constructor() {                        
        document.getElementById("SendSuggestionButton").addEventListener("click",function(){
            document.getElementById("SendSuggestionButton").className += " loading";

            var self = this;    
            self.url = document.body.getAttribute("data-url");                                                          

            var message = document.getElementById("Suggestion").value;

            d3.json(`${self.url}/api/suggestions/suggestion`).header("Content-Type", "application/json")
            .post(JSON.stringify(message), function(error,data) {
                if (!data || error) {     
                                       
                    setTimeout(function() {
                        document.getElementById("SendSuggestionButton").className += " hide-loading";                    
                        document.getElementById("failed").className += " finish";
                    }, 5000);

                    setTimeout(function() {

                        document.getElementById("SendSuggestionButton").className = document.getElementById("SendSuggestionButton").className.replace(/\bloading\b/,"");
                        document.getElementById("SendSuggestionButton").className = document.getElementById("SendSuggestionButton").className.replace(/\bhide-loading\b/,"");

                        document.getElementById("done").className = document.getElementById("done").className.replace(/\bfinish\b/,"");
                        document.getElementById("failed").className = document.getElementById("failed").className.replace(/\bfinish\b/,"");                                               
                    }, 15000);
                }
                else {
                    document.getElementById("Suggestion").readOnly = true;

                    setTimeout(function() {
                        document.getElementById("SendSuggestionButton").className += " hide-loading";
                        document.getElementById("done").className += " finish";
                    }, 3000);
                }            

                document.activeElement.blur();
            });
        });
    }
}

export default PostSuggestion;