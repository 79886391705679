"use strict";

import d3 from "d3";
import odometer from "odometer";

class PotentialLunchGuests {

    constructor(container) {

        var self = this;
        self.container = container;
        self.url = document.body.getAttribute("data-url");
        self.restaurantId = document.body.getAttribute("data-restaurant");
        self.valueSelector = container + " .js-value";
        self.subValueSelector = container + " .js-subvalue";
        self.noteSelector = container + " .js-note";
        self.loaderSelector = container + " .js-loader";
        self.loaderContainerSelector = container + " .js-loader-container";
        self.displayValue = null;

        d3.json(`${self.url}/api/restaurantdata/${self.restaurantId}/lunchguestsarea`)
        .header("Accept-Language", d3.select("html").attr("lang"))
        .get(function(error, data) {

            if (error || data.meta === null) {
                self.displayError();
                return;
            } else {
                self.hideLoader();
            }

            d3.select(self.valueSelector).html(0);
            self.displayValue = data.meta.value;
            d3.select(self.subValueSelector).html(data.meta.subvalue);
            d3.select(self.noteSelector).html(data.meta.note);

            self.scrollHandler();
        });
    }

    scrollHandler() {
        var self = this;
        var notTriggered = true;

        window.addEventListener("scroll", function() {
            var elInfo = document.querySelector(self.container).getBoundingClientRect();

            if (elInfo.top < window.innerHeight && notTriggered) {
                notTriggered = false;
                /*jshint ignore:start*/
                /*eslint-disable*/
                var od = new odometer({
                    el: document.querySelector(self.valueSelector),
                    format: "d",
                    theme: "minimal"
                });
                od.update(self.displayValue);
                /*eslint-enable*/
                /*jshint ignore:end*/
            }
        });
    }

    displayError() {
        var self = this;
        var message = document.querySelector(self.loaderSelector).getAttribute("data-errormessage");
        d3.select(d3.select(self.loaderSelector).node().parentNode).classed("chart--error", true);
        d3.select(self.loaderSelector).html(message);
    }

    hideLoader() {
        var self = this;
        document.querySelector(self.loaderContainerSelector)
            .classList.add("dashboard__loader-container--hidden");
    }
}

export default PotentialLunchGuests;
