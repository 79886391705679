"use strict";

import { getCookie, mediaQuery } from "../../utils/";

class Usp {

    constructor() {

        var self = this;
        self.usp = document.querySelector(".js-usp");
        self.isSticky = false;
        self.isVisible = null;
        self.closeButton = document.querySelector(".js-close-usp");
        self.footerInfo = null;
        self.uspInfo = null;
        self.footerTop = null;
        self.setData();

        // Hide or show the Usp depending on cookie
        if (getCookie("uspStatus") === "closed") {
            self.uspCloseHandler();
        } else {
            self.uspOpenHandler();
        }

        // Set up close button
        self.closeButton.addEventListener("click", function() {
            self.uspCloseHandler(true);
        }.bind(self));

        // Update dom information on resize
        window.addEventListener("resize", function() {
            self.usp.style.top = 0;
            self.setData();
        }.bind(self));

        // Disable Usp stickiness when reaching footer but only in desktop
        window.addEventListener("scroll", function() {
            if (self.isVisible && mediaQuery("min-width: 960px")) {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                if ((scrollTop + self.uspInfo.height) > self.footerTop && !self.isSticky) {
                    self.usp.classList.add("usp--sticky");
                    self.usp.style.top = scrollTop + "px";
                    self.isSticky = true;
                } else if ((scrollTop + self.uspInfo.height) < self.footerTop) {
                    self.usp.classList.remove("usp--sticky");
                    self.usp.style.top = 0;
                    self.isSticky = false;
                }
            }
        });
    }

    setData() {
        var self = this;
        self.footerInfo = document.querySelector(".main-footer").getBoundingClientRect();
        self.uspInfo = self.usp.getBoundingClientRect();
        self.footerTop = self.footerInfo.top || self.footerInfo.y;
    }

    uspCloseHandler(fade = false) {
        var self = this;

        if (fade) {
            self.usp.classList.add("usp--fade");
        } else {
            self.usp.classList.remove("usp--open");
        }

        document.cookie = "uspStatus=closed;path=/";
        self.isVisible = false;
    }

    uspOpenHandler() {
        var self = this;
        self.usp.classList.add("usp--open");
        self.isVisible = true;
    }
}

export default Usp;
